import React, { useState, useEffect, useMemo } from 'react';
import axiosInstance from '../../utils/axiosConfig';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SearchBar from '../../components/SearchBar';
import { Product, Category as CategoryType } from '../../types/types';
import { FaSpinner } from 'react-icons/fa';
import Link from 'next/link';
import axios, { AxiosError } from 'axios';
import Image from 'next/image';

const groupProductsByName = (products: Product[]): { [key: string]: Product[] } => {
  const groupedProducts: { [key: string]: Product[] } = {};
  products.forEach((product) => {
    const name = product.name.trim().toLowerCase();
    if (!groupedProducts[name]) {
      groupedProducts[name] = [];
    }
    groupedProducts[name].push(product);
  });
  return groupedProducts;
};

const HomePage: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axiosInstance.get<Product[]>('/products/');
        console.log("取得した商品データ:", response.data);
        if (Array.isArray(response.data)) {
          setProducts(response.data);
        } else {
          const errorMsg = '商品データの形式が正しくありません。';
          console.error(errorMsg, response.data);
          setError(errorMsg);
          setProducts([]);
        }
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError<{ detail?: string }>;
          setError(axiosError.response?.data?.detail || '商品データの取得エラーが発生しました。');
        } else if (error instanceof Error) {
          setError(`商品データの取得エラー: ${error.message}`);
        } else {
          setError('商品データの取得エラーが発生しました。');
        }
        setProducts([]);
        console.error('商品データの取得エラー:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axiosInstance.get<CategoryType[]>('/categories/');
        console.log("取得したカテゴリーデータ:", response.data);
        if (Array.isArray(response.data)) {
          setCategories(response.data);
        } else {
          const errorMsg = 'カテゴリーデータの形式が正しくありません。';
          console.error(errorMsg, response.data);
          setError(errorMsg);
          setCategories([]);
        }
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError<{ detail?: string }>;
          setError(axiosError.response?.data?.detail || 'カテゴリーデータの取得エラーが発生しました。');
        } else if (error instanceof Error) {
          setError(`カテゴリーデータの取得エラー: ${error.message}`);
        } else {
          setError('カテゴリーデータの取得エラーが発生しました。');
        }
        console.error('カテゴリーデータの取得エラー:', error);
        setCategories([]);
      }
    };    

    fetchProducts();
    fetchCategories();
  }, []);

  const fixedCategoryOrder = useMemo(() => [
    "リップ",
    "ファンデーション",
    "コンシーラー",
    "アイシャドウ",
    "アイブロウ",
    "アイライン",
    "チーク",
    "ハイライト",
    "乳液/クリーム",
    "パック/マスク",
    "シャンプー",
    "トリートメント",    
    "ヘアケア",
    "その他",
  ], []);

  const sortedCategories = useMemo(() => {
    return [...categories].sort((a, b) => {
      const indexA = fixedCategoryOrder.indexOf(a.name);
      const indexB = fixedCategoryOrder.indexOf(b.name);
      if (indexA === -1 && indexB === -1) return 0; 
      if (indexA === -1) return 1; 
      if (indexB === -1) return -1; 
      return indexA - indexB; 
    });
  }, [categories, fixedCategoryOrder]);

  const groupedProducts = useMemo(() => groupProductsByName(products), [products]);
  const productGroups = useMemo(() => Object.values(groupedProducts), [groupedProducts]);

  const getThumbnailImage = (product: Product) => {
    const sortedSKUs = product.skus?.sort((a, b) => {
      const aPrice = a.price || Infinity;
      const bPrice = b.price || Infinity;
      return aPrice - bPrice;
    });

    let thumbnail = sortedSKUs?.[0]?.images?.[0]?.image_url;

    if (!thumbnail || thumbnail === '') {
      thumbnail = product.image_full_url || '/images/placeholder.png';
      console.log(`Product ID ${product.id}: Thumbnail not found in SKUs. Using image_full_url or placeholder.`);
    }

    if (thumbnail.startsWith('http://')) {
      thumbnail = thumbnail.replace('http://', 'https://');
      console.log(`Product ID ${product.id}: Replaced 'http://' with 'https://'. New thumbnail URL: ${thumbnail}`);
    }

    console.log(`Product ID ${product.id}: Final thumbnail URL: ${thumbnail}`);
    return thumbnail;
  };

  return (
    <div className="bg-gray-50 min-h-screen flex flex-col">
      <Header />
        <div className="flex-grow">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="py-4">
              <h1 className="text-lg font-semibold text-gray-700 text-center">
                欲しいコスメの最安値を見つけよう
              </h1>
              <div className="mt-4">
              <SearchBar />
            </div>
          </div>

          {loading ? (
            <div className="flex justify-center items-center">
              <FaSpinner className="animate-spin text-3xl text-gray-500" />
            </div>
          ) : error ? ( 
            <div className="text-red-500 text-center">{error}</div>
          ) : (
            <>
          <section className="mt-8">
            <h2 className="text-lg font-semibold text-gray-700 mb-4">人気商品の最安値</h2>
            <div className="overflow-x-auto whitespace-nowrap space-x-2 flex">
              {productGroups
                .sort((a, b) => {
                  const aPv = a.reduce((sum, product) => sum + (product.pv || 0), 0);
                  const bPv = b.reduce((sum, product) => sum + (product.pv || 0), 0);
                  return bPv - aPv;
                })
                .slice(0, 8)
                .map((productGroup, groupIndex) => {
                  const allPrices: number[] = [];
                  productGroup.forEach((product) => {
                    if (product.price !== null && product.price !== undefined) {
                      allPrices.push(product.price);
                    }
                    if (product.skus && product.skus.length > 0) {
                      const skuPrices = product.skus
                        .map((sku) => sku.price)
                        .filter((price): price is number => price !== null && price !== undefined);
                      allPrices.push(...skuPrices);
                    }
                  });
                  const groupMinPrice = allPrices.length > 0 ? Math.min(...allPrices) : null;
                  const primaryProduct = productGroup.reduce((prev, current) => {
                    const prevPrices = [
                      ...(prev.skus ? prev.skus.map((sku) => sku.price ?? Infinity) : []),
                      prev.price ?? Infinity,
                    ];
                    const currentPrices = [
                      ...(current.skus ? current.skus.map((sku) => sku.price ?? Infinity) : []),
                      current.price ?? Infinity,
                    ];
                    const prevMinPrice = Math.min(...prevPrices);
                    const currentMinPrice = Math.min(...currentPrices);
                    return currentMinPrice < prevMinPrice ? current : prev;
                  }, productGroup[0]);
                  const thumbnail = getThumbnailImage(primaryProduct) || '/images/placeholder.png';
                  return (
                    <div
                      key={`${primaryProduct.id}-${groupIndex}`}
                      className="inline-block w-40 h-64 bg-white border rounded-lg shadow-sm flex-shrink-0"
                    >
                      <Link href={`/products/${primaryProduct.id}`} passHref>
                      <div className="w-full h-36 overflow-hidden rounded-t-lg p-1 bg-white">
                        <Image
                          src={thumbnail}
                          alt={primaryProduct.name}
                          width={160}
                          height={144}
                          className="w-full h-full object-contain"
                          placeholder="blur"
                          blurDataURL="/static/images/placeholder.png"
                        />
                      </div>
                      <div className="p-2">
                        <h3 className="text-sm font-semibold break-words line-clamp-2">{primaryProduct.name}</h3>
                        <p className="text-xs text-gray-500">{primaryProduct.brand || 'ブランド不明'}</p>
                        <p className="text-base font-bold text-red-500">
                        {groupMinPrice ? `最安値: ${new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(groupMinPrice)}` : '価格不明'}
                        </p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </section>

          <section className="mt-8">
            <h2 className="text-lg font-semibold text-gray-700 mb-4">今日のおすすめ</h2>
            <div className="overflow-x-auto whitespace-nowrap space-x-2 flex">
              {productGroups
                .sort(() => 0.5 - Math.random()) 
                .slice(0, 8)
                .map((productGroup, groupIndex) => {
                  const allPrices: number[] = [];
                  productGroup.forEach((product) => {
                    if (product.price !== null && product.price !== undefined) {
                      allPrices.push(product.price);
                    }
                    if (product.skus && product.skus.length > 0) {
                      const skuPrices = product.skus
                        .map((sku) => sku.price)
                        .filter((price): price is number => price !== null && price !== undefined);
                      allPrices.push(...skuPrices);
                    }
                  });
                  const groupMinPrice = allPrices.length > 0 ? Math.min(...allPrices) : null;
                  const primaryProduct = productGroup.reduce((prev, current) => {
                    const prevPrices = [
                      ...(prev.skus ? prev.skus.map((sku) => sku.price ?? Infinity) : []),
                      prev.price ?? Infinity,
                    ];
                    const currentPrices = [
                      ...(current.skus ? current.skus.map((sku) => sku.price ?? Infinity) : []),
                      current.price ?? Infinity,
                    ];
                    const prevMinPrice = Math.min(...prevPrices);
                    const currentMinPrice = Math.min(...currentPrices);
                    return currentMinPrice < prevMinPrice ? current : prev;
                  }, productGroup[0]);

                  const thumbnail = getThumbnailImage(primaryProduct) || '/images/placeholder.png';

                  return (
                    <div
                      key={`${primaryProduct.id}-${groupIndex}`}
                      className="inline-block w-40 h-64 bg-white border rounded-lg shadow-sm flex-shrink-0"
                    >
                      <Link href={`/products/${primaryProduct.id}`} passHref>
                      <a>
                        <div className="w-full h-36 overflow-hidden rounded-t-lg p-1 bg-white">
                          <Image
                            src={thumbnail}
                            alt={primaryProduct.name}
                            width={160}
                            height={144}
                            className="w-full h-full object-contain"
                            placeholder="blur"
                            blurDataURL="/static/images/placeholder.png"
                          />
                        </div>
                        <div className="p-2">
                          <h3 className="text-sm font-semibold break-words line-clamp-2">{primaryProduct.name}</h3>
                          <p className="text-xs text-gray-500">{primaryProduct.brand || 'ブランド不明'}</p>
                          <p className="text-base font-bold text-red-500">
                            {groupMinPrice ? `最安値: ${new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(groupMinPrice)}` : '価格不明'}
                          </p>
                        </div>
                        </a>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </section>
              <section className="mt-8">
                <h2 className="text-lg font-semibold text-gray-700 mb-4">カテゴリ</h2>
                <div className="flex flex-wrap gap-4 justify-center">
                  {sortedCategories.map((category: CategoryType) => (
                    <div
                      key={category.id}
                      className="flex flex-col items-center text-center w-20"
                    >
                    <Link
                      href={`/search?category=${encodeURIComponent(category.name || '')}`}
                      className="w-16 h-16 rounded-full flex items-center justify-center bg-gray-200 overflow-hidden"
                    >
                      {category.image ? (
                        <Image
                          src={category.image}
                          alt={category.name}
                          className="w-full h-full object-cover"
                          width={64}
                          height={64}
                        />
                      ) : (
                        <span className="text-sm text-gray-500">画像なし</span>
                      )}
                    </Link>
                    <p className="text-xs mt-2">{category.name}</p>
                </div>
               ))}
              </div>
              </section>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
